import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/photography/rescuing-details-topaz-labs",
  "date": "2014-07-08",
  "title": "RESCUING THE DETAILS WITH TOPAZ LABS",
  "author": "admin",
  "tags": ["photography"],
  "featuredImage": "feature.jpg",
  "excerpt": "Here are the steps on how to recover shadows and make an image more vibrant with the Topaz Lab’s plug-ins."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">{`Open your image in a compatible host editor. For this demonstration the Topaz standalone editor photo labphotoFXlab will be used, however, our plug-ins can be accessed with a variety of other programs including Photoshop, Photoshop Creative Cloud, Photoshop Elements, Lightroom, iPhoto, Aperture, PaintShop Pro, Serif, and Irfanview.`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/images/2014-07-08-rescuing-details-topaz-labs/garyLamott.jpg",
        "alt": "labphotoFXlab"
      }}></img></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Duplicate the layer (ctrl/cmd + J) and open Topaz Adjust.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to Global Adjustments and expand the Adaptive Exposure panel. Increase adaptive exposure and regions to bring out the shadows in the island.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Up close you will notice that artifacts have formed in the sky and water due to the exposure adjustments. Use the Brush Out tool under the Local Adjustments panel to mask out this effect from the sky and water. Increase the Edge Aware slider when brushing out around the island to maintain a precise edge. Use the Opacity slider to increase or decrease the strength of how much is brushed away.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Press `}<strong parentName="p">{`Apply`}</strong>{` to apply the effect and then press `}<strong parentName="p">{`Reset`}</strong>{` to dispose of the brush out mask so that you can apply additional effects within the Adjust program.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to the Classic Collection and apply the preset `}<strong parentName="p">{`Brilliant Cold`}</strong>{`. If desired, reduce the transparency of the effect under the Finishing Touches panel. This will blend back in the original image with the effect.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Press OK to return to your host editor. Duplicate the layer and open Topaz Clarity.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In Clarity make these adjustments in dynamics and tone. Here’s what was used for this example…`}</p>
      </li>
    </ol>
    <p>{`Black 0.20
Midtones 0.16
White -0.35`}</p>
    <p>{`Micro contrast 0.20
Low contrast 0.25
Medium contrast 0.13
High contrast -0.19`}</p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">
        <p parentName="li">{`Next, make adjustments in the HSL (hue-saturation-luminance) panel. For this example, the overall saturation was boosted to 0.25 and the green luminance was increased to around 0.15.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Press OK. Back in your host editor duplicate the image and open Topaz DeNoise.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use a preset in DeNoise that will reduce all artifacts seen in the image. This particular example used the `}<strong parentName="p">{`JPEG – strongest`}</strong>{` preset. Use the upper-right preview image to navigate around the main photo. Press OK when content with the results.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When finished making all adjustments, the last step in this workflow is to prepare the image for output with detail sharpening. Duplicate the layer and open Topaz Detail.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use the effect mask to create a mask around the island and then invert that selection.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Under the Creative Detail Collection apply the preset, `}<strong parentName="p">{`Micro Contrast Enhancement II`}</strong>{` to bring out details in the island. Press OK when complete.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save the image.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      